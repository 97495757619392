import PageLayout from '@/layouts/PageLayout'
import { AlertsV1View } from '@/modules/alerts/views/AlertsV1View'

export default function Home() {
  return (
    <PageLayout showNewAlertBtn={true}>
      <AlertsV1View />
    </PageLayout>
  )
}
